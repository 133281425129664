:root {
  /* colors */
  --clr-dark: 230 35% 7%;
  --clr-light: 231 77% 90%;
  --clr-white: 0 0% 100%;
}

.nav-link-text-white {
  color: hsl(var(--clr-white));
}

.nav-link-text-white>*:hover {
  color: hsl(var(--clr-white));
}

.flex {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.primary-header {
  height: 5rem;
  padding-inline: 4rem;
  background-color: #246689;
  overflow-x: hidden;
}

.logo {
  width: 12rem;
}

.mobile-nav-toggle {
  display: none;
}

.primary-navigation {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 75.5rem) {
  .primary-header {
    padding-inline: 4rem;
  }

  .primary-navigation {
    position: fixed;
    z-index: 1000;
    inset: 0 0 0 50%;
    background: #246689;
    list-style: none;
    padding: min(20rem, 15vh) 2rem;
    margin: 0;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 500ms ease-in-out;
  }

  .primary-navigation[data-visible="true"] {
    transform: translateX(0);
  }

  .mobile-nav-toggle {
    display: block;
    position: absolute;
    z-index: 2000;
    right: 4rem;
    top: 2rem;
    background: transparent;
    background-image: url("../../../assets/images/menu-logo.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 1.5rem;
    height: 1.5rem;
    border: 0;
  }

  .mobile-nav-toggle[aria-expanded="true"] {
    background-image: url("../../../assets/images/menu-close-logo.svg");
  }
}

.primary-navigation a li {
  color: white;
}

.underline-indicators>* {
  cursor: pointer;
  padding: 0.5rem 0;
  border: 0;
  border-bottom: .2rem solid hsl(var(--clr-white) / 0);
}

.underline-indicators>*:focus {
  border-color: hsl(var(--clr-white) / .5);
}

@media (hover: hover) {
  .underline-indicators>*:hover {
    border-color: hsl(var(--clr-white) / .5);
  }
}

@media (min-width: 35rem) {
  .underline-indicators>.active,
  .underline-indicators>[aria-selected="true"] {
    color: hsl(var(--clr-white) / 1);
    border-color: hsl(var(--clr-white) / 1);
  }
}

@media screen and (max-width: 62.5rem) {
  .mobile-nav-toggle {
    right: 1rem;
  }

  .primary-header {
    padding-inline: 1rem;
  }
}

@media screen and (max-width: 35rem) {
  .mobile-nav-toggle {
    right: 1rem;
  }
  
  .primary-header {
    padding-inline: 1rem;
  }
}