.corporativo {
  position: relative;
  min-height: 80vh;

  .img-corporativo {
    position: absolute;
    width: 21%;
    bottom: -1.2%;
    right: 0%;
  }
}

.corporate-left-section {
  height: 70vh;
  padding: 2rem;
}

.corporate-left-section-image {
  height: 100%;
  width: 100%;
}

.corporate-right-section {
  padding: 1rem;
}

@media only screen and (max-width: 768px) {
  .corporate-right-section {
    height: 55vh;
  }
}