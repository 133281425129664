.footer-main-container {
  width: 100%;
  background-color: #246689;
}

.footer-main-section {
  display: flex;
  justify-content: center;
  gap: 5rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.footer-secondary-section {
  margin: 2rem 0;
  text-align: center;
  color: lightgrey;
}

.footer-info-section {
  color: white;
  font-family: 'Nunito', sans-serif;
  line-height: 2em;
  font-weight: 300;
}
.footer-info-section span {
  font-family: 'Nunito', sans-serif;
}

.footer-info-section h2{
  font-size: 1.5em;
  font-weight: 600;
  color: white;
}

.footer-info-section a {
  color: white;
  margin-right: 1rem;
}

.footer-info-section img {
  vertical-align: sub;
}

.footer-info-section a:hover,
.footer-secondary-section a:hover {
  color: rgb(255, 255, 119);
}

@media only screen and (max-width: 800px) {
  .footer-main-section {
    flex-direction: column;
    gap: 2rem;
    margin-left: 3rem;
  }

  .footer-secondary-section {
    margin-left: 3rem;
    text-align: start;
  }
}