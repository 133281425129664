.card {
  width: 70%;
  height: 57%;
  margin-left: 15%;
  background-color: transparent;
  border: none;
  line-height: 1.3rem;
}

.card p {
  width: 70%;
}
.card a {
  color: #FF8165;
}

.institucionalCard{
  margin-top: 50px;
}