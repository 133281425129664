/* .table-body-scroll {
  max-height: 17rem;
  overflow-y: auto;
}

@media only screen and (min-height: 700px) {
  .table-body-scroll {
    max-height: 25rem;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 850px) {
  .table-body-scroll {
    max-height: 35rem;
    overflow-y: auto;
  }
} */

.table{
  margin-bottom: 40px;
}
.button-footer {
  display: flex;
  justify-content: space-between;
}
.ant-select-arrow{
  right: 0;
  width: 1rem;
}
.ant-select-item-option-content{
  white-space: break-spaces!important;
}
.ant-pagination-options{
  visibility: hidden!important;
}

.userInfoform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #f7f7f7;
  max-width: 500px;
  margin-bottom: 20px;
  
}
.userInfoform h5 {
  align-self: flex-start;
}
.userInfoform a {
  color: blue
}
.userInfoform .ant-form-item {
  width: 100%;
  margin-bottom: 16px;
}

.userInfoform .ant-form-item-label {
  text-align: left;
}

.userInfoform .ant-form-item-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userInfoform .ant-form-item-control-input {
  width: 100%;
}

.userInfoform .ant-btn {
  width: 100%;
}


@media screen and (max-width: 576px) {
  .userInfoform {
    padding: 12px;
    width: 100%;
  }
  
  .userInfoform .ant-form-item-label {
    text-align: center;
  }

  .ant-table table { font-size: 11px; }
}