* {
  font-family: 'Nunito', sans-serif;
  box-sizing: border-box;
}


.container {
  background-color: white;
  width: 70%;
  margin: 4% auto;
  border-radius: 15px;

}


.img-fondo {
  width: 30%;

}

.foto{
  width: 50%;
  height: 50%;
  display: flex;
}

.titulo {
  color: #FF8165;
  text-align: center;
  height: 20%;
  padding: 1rem;

}

.info {
  width: 40%;
  height: 50%;
}

.form {
  width: 50%;
  align-items: center;
  flex-direction: column-reverse;

}

.formulario {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

#campo-form {
  box-sizing: border-box;
  color: rgba(0, 0, 0, .88);
  font-size: 14px;
  border-radius: 6px;
  border-width: 1px;
  border-color: #d9d9d9;
  height: 35px;

}

.botonEnviar {
  color: white;
  background-color: #FF8165;
  width: 100%;
  margin-top: 10px;
}

.anticon {
  width: 2rem;
  font-size: 17px;
}


.rrss {
  padding-left: 20%;
  font-size: 1.5rem;

}

.cv {

  text-align: center;
  font-size: small;
  margin-right: 140px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.titulo {
  text-align: center;
}

.container2 {
  display: flex;
  justify-content: space-around;
}



.form {
  width: 100%;
  max-width: 400px;
}

#campo-form {
  width: 100%;
}

@media (max-width: 950px) {
  .img-fondo {
    display: none;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  .container2 {
    flex-wrap: wrap;
  }

  .formulario {
    margin: 0;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .botonEnviar {
    left: 0px;
  }
}