.search { 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.buttons {
  width: 30%;
  margin-left: 5px;
}

.table {
  padding-left: 50px;
  padding-right: 50px;
}

img.img-bici {
  position: absolute;
  top: 0;
  right: 0;
  width: 8%;
  margin-top: 1rem;
}

@media only screen and (max-width: 902px) {
  .table {
    padding-left: 5px;
    padding-right: 5px;
  }

  .seleccion {
    display: flex;
    flex-direction: column;
  }

  .ant-picker-panels {
    flex-direction: column;
  }

  img.img-bici {
    top: 20px;
    width: 15%;
  }
}

.table-body-scroll {
  max-height: 17rem;
  overflow-y: auto;
}

@media only screen and (min-height: 700px) {
  .table-body-scroll {
    max-height: 23rem;
    overflow-y: auto;

  }
}

@media only screen and (min-height: 850px) {
  .table-body-scroll {
    max-height: 25rem;
    overflow-y: auto;
  }
}

@media screen and (max-width: 750px) {
  .tikets-input-table {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .img-bici {
    display: none;
  }

  .search {
    flex-direction: column;

    .buttons button {
      margin-top: 10px;
    }
  }

  .button-footer {
    width: 100%;
  }

  .tikets-input-table {
    width: 100%;
  }
}