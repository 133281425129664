
.search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .buttons {
    margin-left: 5px;
  }
  
  .table {
    padding-left: 50px;
    padding-right: 50px;
  }
  
  /* .seleccion {
    display: flex;
    justify-content: space-between;
  } */
  
  .button-footer {
    /* margin-top: 1rem; */
    width: 100%;
    /* display: flex;
    flex-direction: row;
    align-items: flex-end;
    background-color: aquamarine; */
  }

  .info-container{
    display: flex;
    flex: 1;
    justify-content: space-around;
    > div{  
      width: 50%;
      /* margin: 30px; */
      margin-bottom: 2rem;
    }
  }
  
  @media only screen and (max-width: 902px) {
    .table {
      padding-left: 0px;
      padding-right: 0px;
    }
  
    .seleccion {
      display: flex;
      flex-direction: column;
    }
    .ant-picker-panels { 
      flex-direction: column;
    } 
  }
  
  .table-body-scroll {
    max-height: 17rem;
    overflow-y: auto;
  }
  .search-title,.search {
    margin: 0 auto;
  }
  .search-title{
    margin-top: 15px;
  }
  
  @media only screen and (min-height: 700px) {
    .table-body-scroll {
      max-height: 23rem;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 850px) {
    .table-body-scroll {
      max-height: 25rem;
      overflow-y: auto;
    }
  }
  
  @media only screen and (max-width: 902px) {
    .info-container{
      justify-content: center;
      align-items: center;
      flex-direction: column;
      > div{  
        width: 100%;
        margin: 10px;
      }
    }
  }