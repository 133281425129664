.main-container{
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
}
.main-container.content {
    flex: 1 1 auto;
  }
  
  @media only screen and (max-width: 1000px) {

      .main-container{
      padding: 0 1rem;}
  }
  
  @media only screen and (max-width: 700px) {
    .main-container{
    padding: 0 5px;}
}