.card {
  width: 70%;
  height: 57%;
  margin-left: 15%;
  background-color: transparent;
  border: none;
  line-height: 1.3rem;

}

.card {
  width: 70%;
}

.card .btn {
  width: 30%;
}

.card a {
  color: #FF8165;
}

.institucional-section {
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 90vh;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.institucional-section {
  .img-fondo {
    position: absolute;
    width: 40%;
    right: 16%;
  }
}

.card-desktop {
  font-family: 'Nunito', sans-serif;
  height: 10rem;
  width: 100%;
  font-size: 1.2em;
}

.card-desktop h2,
.card-mobile h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.card-desktop p,
.card-mobile p {
  text-align: justify;
}

.card-mobile {
  font-family: 'Nunito', sans-serif;
  margin-bottom: 5rem;
  align-items: center;
  font-size: 1.2em;
}

.img-fondo2 {
  width: 80%;
}

.collaborators {
  font-family: 'Nunito', sans-serif;
  font-size: 2em;
}

.desktop-main-column {
  min-height: 20rem;
  margin: 5rem 0
}

@media only screen and (max-width: 500px) {
  .institucional-section {
    margin-bottom: 2rem;
    min-height: 70vh;

    .card p {
      width: 100%;
    }

    .card {
      .btn {
        width: 100%;
      }
    }
  }
}