@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&family=Playfair+Display&family=Poppins:wght@200;400;700&display=swap);
:root {
  /* colors */
  --clr-dark: 230 35% 7%;
  --clr-light: 231 77% 90%;
  --clr-white: 0 0% 100%;
}

.nav-link-text-white {
  color: hsl(var(--clr-white));
}

.nav-link-text-white>*:hover {
  color: hsl(var(--clr-white));
}

.flex {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.primary-header {
  height: 5rem;
  padding-inline: 4rem;
  background-color: #246689;
  overflow-x: hidden;
}

.logo {
  width: 12rem;
}

.mobile-nav-toggle {
  display: none;
}

.primary-navigation {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 75.5rem) {
  .primary-header {
    padding-inline: 4rem;
  }

  .primary-navigation {
    position: fixed;
    z-index: 1000;
    inset: 0 0 0 50%;
    background: #246689;
    list-style: none;
    padding: min(20rem, 15vh) 2rem;
    margin: 0;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 500ms ease-in-out;
  }

  .primary-navigation[data-visible="true"] {
    transform: translateX(0);
  }

  .mobile-nav-toggle {
    display: block;
    position: absolute;
    z-index: 2000;
    right: 4rem;
    top: 2rem;
    background: transparent;
    background-image: url(/static/media/menu-logo.f8d910f8.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 1.5rem;
    height: 1.5rem;
    border: 0;
  }

  .mobile-nav-toggle[aria-expanded="true"] {
    background-image: url(/static/media/menu-close-logo.7b5a6d89.svg);
  }
}

.primary-navigation a li {
  color: white;
}

.underline-indicators>* {
  cursor: pointer;
  padding: 0.5rem 0;
  border: 0;
  border-bottom: .2rem solid hsl(var(--clr-white) / 0);
}

.underline-indicators>*:focus {
  border-color: hsl(var(--clr-white) / .5);
}

@media (hover: hover) {
  .underline-indicators>*:hover {
    border-color: hsl(var(--clr-white) / .5);
  }
}

@media (min-width: 35rem) {
  .underline-indicators>.active,
  .underline-indicators>[aria-selected="true"] {
    color: hsl(var(--clr-white) / 1);
    border-color: hsl(var(--clr-white) / 1);
  }
}

@media screen and (max-width: 62.5rem) {
  .mobile-nav-toggle {
    right: 1rem;
  }

  .primary-header {
    padding-inline: 1rem;
  }
}

@media screen and (max-width: 35rem) {
  .mobile-nav-toggle {
    right: 1rem;
  }
  
  .primary-header {
    padding-inline: 1rem;
  }
}
.main-container{
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
}
.main-container.content {
    flex: 1 1 auto;
  }
  
  @media only screen and (max-width: 1000px) {

      .main-container{
      padding: 0 1rem;}
  }
  
  @media only screen and (max-width: 700px) {
    .main-container{
    padding: 0 5px;}
}
.footer-main-container {
  width: 100%;
  background-color: #246689;
}

.footer-main-section {
  display: flex;
  justify-content: center;
  grid-gap: 5rem;
  gap: 5rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.footer-secondary-section {
  margin: 2rem 0;
  text-align: center;
  color: lightgrey;
}

.footer-info-section {
  color: white;
  font-family: 'Nunito', sans-serif;
  line-height: 2em;
  font-weight: 300;
}
.footer-info-section span {
  font-family: 'Nunito', sans-serif;
}

.footer-info-section h2{
  font-size: 1.5em;
  font-weight: 600;
  color: white;
}

.footer-info-section a {
  color: white;
  margin-right: 1rem;
}

.footer-info-section img {
  vertical-align: sub;
}

.footer-info-section a:hover,
.footer-secondary-section a:hover {
  color: rgb(255, 255, 119);
}

@media only screen and (max-width: 800px) {
  .footer-main-section {
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    margin-left: 3rem;
  }

  .footer-secondary-section {
    margin-left: 3rem;
    text-align: start;
  }
}
.card {
  width: 70%;
  height: 50%;
  margin-left: 15%;
  z-index: 2;
  background-color: transparent;
  border: none;
  line-height: 1.3rem;
}

.card .btn {
  width: 30%;
}

.card p {
  width: 70%;  
}

.homesection-container {
  flex: 1 1;
  align-items: center;
  min-height: 70vh;

  .img-fondo {
    position: absolute;
    width: 25%;
    bottom: 80%;
    right: 47%;
    z-index: 0;
  }
}

.home-card {
  margin-top: 5rem;
  font-size: 1.2em;
}

.home-card h1 {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}

.home-card p {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  text-align: justify;
}

.home-slider-container-mobile {
  height: 30rem;
  margin-top: 5rem;
}

@media only screen and (max-width: 900px) {
  .homesection-container {
    .img-fondo {
      bottom: -83%;
      right: 20%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .homesection-container {
    .card p {
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  }

  .home-slider-container-mobile {
    height: 25rem;
    margin-bottom: 5rem;
  }

}
.card {
  width: 70%;
  height: 57%;
  margin-left: 15%;
  background-color: transparent;
  border: none;
  line-height: 1.3rem;

}

.card {
  width: 70%;
}

.card .btn {
  width: 30%;
}

.card a {
  color: #FF8165;
}

.institucional-section {
  display: flex;
  flex: 1 1;
  align-items: center;
  min-height: 90vh;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.institucional-section {
  .img-fondo {
    position: absolute;
    width: 40%;
    right: 16%;
  }
}

.card-desktop {
  font-family: 'Nunito', sans-serif;
  height: 10rem;
  width: 100%;
  font-size: 1.2em;
}

.card-desktop h2,
.card-mobile h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.card-desktop p,
.card-mobile p {
  text-align: justify;
}

.card-mobile {
  font-family: 'Nunito', sans-serif;
  margin-bottom: 5rem;
  align-items: center;
  font-size: 1.2em;
}

.img-fondo2 {
  width: 80%;
}

.collaborators {
  font-family: 'Nunito', sans-serif;
  font-size: 2em;
}

.desktop-main-column {
  min-height: 20rem;
  margin: 5rem 0
}

@media only screen and (max-width: 500px) {
  .institucional-section {
    margin-bottom: 2rem;
    min-height: 70vh;

    .card p {
      width: 100%;
    }

    .card {
      .btn {
        width: 100%;
      }
    }
  }
}
.card {
  width: 70%;
  height: 57%;
  margin-left: 15%;
  background-color: transparent;
  border: none;
  line-height: 1.3rem;
}

.card p {
  width: 70%;
}
.card a {
  color: #FF8165;
}

.institucionalCard{
  margin-top: 50px;
}
.steptitle{
    width: 100%;
    padding: 1rem;
}

/* .table-body-scroll {
  max-height: 17rem;
  overflow-y: auto;
}

@media only screen and (min-height: 700px) {
  .table-body-scroll {
    max-height: 25rem;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 850px) {
  .table-body-scroll {
    max-height: 35rem;
    overflow-y: auto;
  }
} */

.table{
  margin-bottom: 40px;
}
.button-footer {
  display: flex;
  justify-content: space-between;
}
.ant-select-arrow{
  right: 0;
  width: 1rem;
}
.ant-select-item-option-content{
  white-space: break-spaces!important;
}
.ant-pagination-options{
  visibility: hidden!important;
}

.userInfoform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #f7f7f7;
  max-width: 500px;
  margin-bottom: 20px;
  
}
.userInfoform h5 {
  align-self: flex-start;
}
.userInfoform a {
  color: blue
}
.userInfoform .ant-form-item {
  width: 100%;
  margin-bottom: 16px;
}

.userInfoform .ant-form-item-label {
  text-align: left;
}

.userInfoform .ant-form-item-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userInfoform .ant-form-item-control-input {
  width: 100%;
}

.userInfoform .ant-btn {
  width: 100%;
}


@media screen and (max-width: 576px) {
  .userInfoform {
    padding: 12px;
    width: 100%;
  }
  
  .userInfoform .ant-form-item-label {
    text-align: center;
  }

  .ant-table table { font-size: 11px; }
}
body {
  background-color: white;
}

.flexbox {
  display: flex;
  background-color: white;
  /* height: 76vh; */
}


.container9 {
  /* width: auto; */
  padding-left: 20px;
  padding-top: 15px;
  margin: 20px;
  flex-direction: column;
  background-color: white;
}

h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.list {
  padding: 10px;
}

.reser_becas {
  display: flex;
}

.buttonDownload {
  background-color: #FF8165;
  padding-right: 3rem;
  padding-left: 3rem;
}


.becas {
  padding-left: 10%;
}

@media only screen and (max-width: 500px) {
  .reser_becas {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    .becas {
      margin-top: 20px;
      padding-left: 0;
    }
  }
}
* {
  font-family: 'Nunito', sans-serif;
  box-sizing: border-box;
}


.container {
  background-color: white;
  width: 70%;
  margin: 4% auto;
  border-radius: 15px;

}


.img-fondo {
  width: 30%;

}

.foto{
  width: 50%;
  height: 50%;
  display: flex;
}

.titulo {
  color: #FF8165;
  text-align: center;
  height: 20%;
  padding: 1rem;

}

.info {
  width: 40%;
  height: 50%;
}

.form {
  width: 50%;
  align-items: center;
  flex-direction: column-reverse;

}

.formulario {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

#campo-form {
  box-sizing: border-box;
  color: rgba(0, 0, 0, .88);
  font-size: 14px;
  border-radius: 6px;
  border-width: 1px;
  border-color: #d9d9d9;
  height: 35px;

}

.botonEnviar {
  color: white;
  background-color: #FF8165;
  width: 100%;
  margin-top: 10px;
}

.anticon {
  width: 2rem;
  font-size: 17px;
}


.rrss {
  padding-left: 20%;
  font-size: 1.5rem;

}

.cv {

  text-align: center;
  font-size: small;
  margin-right: 140px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.titulo {
  text-align: center;
}

.container2 {
  display: flex;
  justify-content: space-around;
}



.form {
  width: 100%;
  max-width: 400px;
}

#campo-form {
  width: 100%;
}

@media (max-width: 950px) {
  .img-fondo {
    display: none;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  .container2 {
    flex-wrap: wrap;
  }

  .formulario {
    margin: 0;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .botonEnviar {
    left: 0px;
  }
}

.search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .buttons {
    margin-left: 5px;
  }
  
  .table {
    padding-left: 50px;
    padding-right: 50px;
  }
  
  /* .seleccion {
    display: flex;
    justify-content: space-between;
  } */
  
  .button-footer {
    /* margin-top: 1rem; */
    width: 100%;
    /* display: flex;
    flex-direction: row;
    align-items: flex-end;
    background-color: aquamarine; */
  }

  .info-container{
    display: flex;
    flex: 1 1;
    justify-content: space-around;
    > div{  
      width: 50%;
      /* margin: 30px; */
      margin-bottom: 2rem;
    }
  }
  
  @media only screen and (max-width: 902px) {
    .table {
      padding-left: 0px;
      padding-right: 0px;
    }
  
    .seleccion {
      display: flex;
      flex-direction: column;
    }
    .ant-picker-panels { 
      flex-direction: column;
    } 
  }
  
  .table-body-scroll {
    max-height: 17rem;
    overflow-y: auto;
  }
  .search-title,.search {
    margin: 0 auto;
  }
  .search-title{
    margin-top: 15px;
  }
  
  @media only screen and (min-height: 700px) {
    .table-body-scroll {
      max-height: 23rem;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 850px) {
    .table-body-scroll {
      max-height: 25rem;
      overflow-y: auto;
    }
  }
  
  @media only screen and (max-width: 902px) {
    .info-container{
      justify-content: center;
      align-items: center;
      flex-direction: column;
      > div{  
        width: 100%;
        margin: 10px;
      }
    }
  }
.corporativo {
  position: relative;
  min-height: 80vh;

  .img-corporativo {
    position: absolute;
    width: 21%;
    bottom: -1.2%;
    right: 0%;
  }
}

.corporate-left-section {
  height: 70vh;
  padding: 2rem;
}

.corporate-left-section-image {
  height: 100%;
  width: 100%;
}

.corporate-right-section {
  padding: 1rem;
}

@media only screen and (max-width: 768px) {
  .corporate-right-section {
    height: 55vh;
  }
}
.cumpleaños a {
  color: blue
}

.cumpleaños h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.cumpleaños p,
.cumpleaños li {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
}
.search { 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.buttons {
  width: 30%;
  margin-left: 5px;
}

.table {
  padding-left: 50px;
  padding-right: 50px;
}

img.img-bici {
  position: absolute;
  top: 0;
  right: 0;
  width: 8%;
  margin-top: 1rem;
}

@media only screen and (max-width: 902px) {
  .table {
    padding-left: 5px;
    padding-right: 5px;
  }

  .seleccion {
    display: flex;
    flex-direction: column;
  }

  .ant-picker-panels {
    flex-direction: column;
  }

  img.img-bici {
    top: 20px;
    width: 15%;
  }
}

.table-body-scroll {
  max-height: 17rem;
  overflow-y: auto;
}

@media only screen and (min-height: 700px) {
  .table-body-scroll {
    max-height: 23rem;
    overflow-y: auto;

  }
}

@media only screen and (min-height: 850px) {
  .table-body-scroll {
    max-height: 25rem;
    overflow-y: auto;
  }
}

@media screen and (max-width: 750px) {
  .tikets-input-table {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .img-bici {
    display: none;
  }

  .search {
    flex-direction: column;

    .buttons button {
      margin-top: 10px;
    }
  }

  .button-footer {
    width: 100%;
  }

  .tikets-input-table {
    width: 100%;
  }
}
html {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  background-color: #F4F4F3;
}

p {
  font-family: 'Nunito', sans-serif;
}

li {
  font-family: 'Nunito', sans-serif;
}

a:link, a:visited, a:active {
  text-decoration:none;
}

