.cumpleaños a {
  color: blue
}

.cumpleaños h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.cumpleaños p,
.cumpleaños li {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
}