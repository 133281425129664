body {
  background-color: white;
}

.flexbox {
  display: flex;
  background-color: white;
  /* height: 76vh; */
}


.container9 {
  /* width: auto; */
  padding-left: 20px;
  padding-top: 15px;
  margin: 20px;
  flex-direction: column;
  background-color: white;
}

h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.list {
  padding: 10px;
}

.reser_becas {
  display: flex;
}

.buttonDownload {
  background-color: #FF8165;
  padding-right: 3rem;
  padding-left: 3rem;
}


.becas {
  padding-left: 10%;
}

@media only screen and (max-width: 500px) {
  .reser_becas {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    .becas {
      margin-top: 20px;
      padding-left: 0;
    }
  }
}