.card {
  width: 70%;
  height: 50%;
  margin-left: 15%;
  z-index: 2;
  background-color: transparent;
  border: none;
  line-height: 1.3rem;
}

.card .btn {
  width: 30%;
}

.card p {
  width: 70%;  
}

.homesection-container {
  flex: 1;
  align-items: center;
  min-height: 70vh;

  .img-fondo {
    position: absolute;
    width: 25%;
    bottom: 80%;
    right: 47%;
    z-index: 0;
  }
}

.home-card {
  margin-top: 5rem;
  font-size: 1.2em;
}

.home-card h1 {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}

.home-card p {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  text-align: justify;
}

.home-slider-container-mobile {
  height: 30rem;
  margin-top: 5rem;
}

@media only screen and (max-width: 900px) {
  .homesection-container {
    .img-fondo {
      bottom: -83%;
      right: 20%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .homesection-container {
    .card p {
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  }

  .home-slider-container-mobile {
    height: 25rem;
    margin-bottom: 5rem;
  }

}