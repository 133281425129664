@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&family=Playfair+Display&family=Poppins:wght@200;400;700&display=swap');

html {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  background-color: #F4F4F3;
}

p {
  font-family: 'Nunito', sans-serif;
}

li {
  font-family: 'Nunito', sans-serif;
}

a:link, a:visited, a:active {
  text-decoration:none;
}